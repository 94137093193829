// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui-pro/scss/coreui.scss';
@import '~@coreui/coreui-pro/scss/themes/dark.scss';

// Some temp fixes
@import 'layout';

// Some temp fixes
@import 'fixes';

// If you want to add something do it here
@import 'custom';
