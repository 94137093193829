// Here you can add other styles

body.dark-theme {
  .wrapper{
    color: hsla(0, 0%, 100%, .87);
    background-color: #181924;
    --color: hsla(0, 0%, 100%, 0.87);
    --elevation-base-color: 0, 0, 0;
  }
  .picker-input-group .form, .picker-input-group .form-control[readonly], .picker-input-group .form-control.form-control:disabled + .input-group-text{
    color: hsla(0, 0%, 100%, .87);
    background-color: hsla(0, 0%, 100%, .05) !important;
    border-color: hsla(0, 0%, 100%, .09);
  }
  .form-control {
    color: hsla(0, 0%, 100%, .87);
    background-color: hsla(0, 0%, 100%, .05);
    border-color: hsla(0, 0%, 100%, .09);
  }
}
.c-dark-theme {
  .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    color: rgba(255, 255, 255, 0.87);
  }
}
.bgLogin {
  background: round;
}
.col-button {
  padding-top: 2rem;
}
.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.sidebar {
  background-image: url(https://crm.cruzinsurancegroup.com/images/SIDEBAR_CIG.jpg);
  background-position: 50% 50%;
  background-size: cover;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #eeeeee;
}
